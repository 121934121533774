<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-data-table
                    class="custom-data-table elevation-1"
					dense
					:headers="monthHeaders"
					:loading="$store.getters['month/loading']"
					:items="$store.getters['month/months']"
					:items-per-page="10"
					:search="monthSearch"
				>
					<template v-slot:top>
						<v-toolbar dense color="white" :elevation="1" height="34px" class="custom-toolbar mb-2">
							<v-toolbar-title class="subtitle-2">Month List</v-toolbar-title>
							<v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form"> 
                                <v-text-field v-model="monthSearch" dense single-line hide-details outlined placeholder="Search Month" autocomplete="off" class="table-search">
                                    <v-icon slot="append">mdi-magnify</v-icon>
                                </v-text-field>
                            </v-form>
                            <v-spacer></v-spacer>
                            <month-dialog :icon="true" ref="monthDialog"></month-dialog>
						</v-toolbar>
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editMonth(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="monthIdToDelete = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
				</v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteMonth"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";
import monthDialog from "./../../components/Month";

export default {
    name: 'Month',
    components: {
		"confirm-dialog": confirmDialog,
		"month-dialog": monthDialog,
    },

    data: ()=> ({
        monthHeaders: [
            { text: 'Sl', value: 'sl' },
            { text: 'Name', value: 'name' },
            { text: 'Action ', value: 'action' },
        ],
        monthSearch: '',
        monthIdToDelete: null,
    }),

    created() {
        this.getMonth();
    },

    methods: {
        getMonth() {
            this.$store.dispatch('month/getMonth')
        },

        async editMonth(month) {
           Object.keys(this.$refs.monthDialog.month).forEach(key => {
                this.$refs.monthDialog.month[key] = month[key];
            })

            this.$refs.monthDialog.showMonthDialog = true;
        },

        deleteMonth() {
            this.$store.dispatch('month/deleteMonth', { id: this.monthIdToDelete });
            this.$refs.confirmDialog.dialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>
</style>