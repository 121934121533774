<template>
	<v-dialog v-model="showMonthDialog" max-width="300" light>
		<template v-slot:activator="{ on: dialog }" v-if="icon">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn fab x-small depressed color="primary" v-on="{...dialog, ...tooltip}">
						<v-icon dark>mdi-plus</v-icon>
					</v-btn>
				</template>
				<span>Add month</span>
			</v-tooltip>
		</template>
		<template v-slot:activator="{ on }" v-else>
			<v-btn color="primary" dark v-on="on">Add Month</v-btn>
		</template>
		<v-card>
			<v-form ref="monthForm" @submit.prevent="saveMonth">
                <v-card-title>Month Name</v-card-title>
				<v-card-text>
					<v-text-field
						dense
						v-model="month.name"
						outlined
						autocomplete="off"
						:rules="[() => !!month.name || 'Enter month name']"
					></v-text-field>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" dark type="submit">Save</v-btn>
					<v-btn color="error" dark @click="closeMonthDialog">Close</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
    data: () => ({
        month: {
			id: null,
            name: null
		},
		showMonthDialog: false,
	}),

	props: {
		icon: false
	},

	methods: {
		closeMonthDialog() {
			this.resetMonth();
			setTimeout(() => {
				this.showMonthDialog = false;
			}, 100);
		},

		async saveMonth() {
            let isValid = true;
            this.$refs.monthForm.validate();
            this.$refs.monthForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            if(!isValid) {
                return;
            }
		
			let isSuccess = await this.$store.dispatch('month/saveMonth', this.month);
			if(isSuccess) {
				if(this.month.id == null) {
					this.resetMonth();
				} else {
					this.closeMonthDialog();
				}
			}
        },

		resetMonth() {
			this.$refs.monthForm.reset();
			Object.keys(this.month).forEach(key => {
				this.month[key] = null;
            })
            this.$refs.monthForm.resetValidation();
		}
	}
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--x-small {
    height: 26px;
    width: 29px;
}
</style>